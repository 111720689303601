import withCache from "./../helpers/cache";
import myFetch from "./myFetch";

const getParams = ({ page, search, history_of, history_mode }) => {
  const params = new URLSearchParams();

  if (page) params.append("page", page);

  if (search) params.append("search", search);

  if (history_mode) {
    params.append("history_mode", true);
  } else if (history_of && !history_mode) {
    params.append("history_of", history_of);
    params.append("history_mode", true);
  };

  const stringifiedParams = params.toString();

  return stringifiedParams ? `?${stringifiedParams}` : "";
};

const loadExercises = (params) => {
  const queryString = getParams(params);

  return myFetch(`/api/exercises/${queryString}`);
};

const loadExercisesForCurrentUser = (params) => {
  const queryString = getParams(params);

  return myFetch(`/api/exercises/current-user${queryString}`);
};

export const loadFavExercises = (params) => {
  const queryString = getParams(params);

  return myFetch(`/api/exercises/fav${queryString}`);
};

export const loadFavExercisesWithCache = withCache(loadFavExercises);

export const loadExercisesWithCache = withCache(loadExercises);

export const loadExercisesForCurrentUserWithCache = withCache(
  loadExercisesForCurrentUser
);

export const addExercises = ({ body }) => {
  loadExercisesWithCache.invalidate();
  loadExercisesForCurrentUserWithCache.invalidate();
  return myFetch("/api/exercises/", { method: "POST", body: body });
};

export const editExercises = ({ body, id }) => {
  loadExercisesWithCache.invalidate();
  loadExercisesForCurrentUserWithCache.invalidate();
  return myFetch(`/api/exercises/${id}/`, {
    method: "PATCH",
    body: body,
  });
};

export const deleteExercises = ({ id }) => {
  loadExercisesWithCache.invalidate();
  loadExercisesForCurrentUserWithCache.invalidate();
  return myFetch(`/api/exercises/${id}/`, { method: "DELETE" }, null);
};
